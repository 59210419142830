import { Reducer } from 'redux';
import _, { compact, flatten } from 'lodash';
import {
  BaseProfileData,
  Console,
  ConsoleSet,
  ConsoleSetAssignment,
  CVKurve,
  getUpdatedElementIfValueChanges,
  replaceElement,
  replaceInList,
  Series,
  SeriesENMaxStrokeRoof,
  System,
  TestPhaseApplicationOverviewWithOptionalId,
  TestPhaseWindLoadOrSnowLoadWithOptionalId,
} from './adminFacadeReducer';
import { ADMIN_ROOF_ACTIONS } from './adminRoofActions';
import { Funktionssicherheit } from '../../admin/constants';
import { TEST_PHASE_ROOF_ACTIONS } from './editedTestPhaseActionsRoof';
import { fieldsFilledRecord } from '../../admin/general/helpers';
import { Edit } from '../../types';
import { AERODYNAMIC_CASE } from '../constants';

export enum ACTIVE_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PREVIEW = 'PREVIEW',
}

export interface AdminRoofState {
  windDeflectors: WindDeflectorData[] | undefined;
  cvKurven: CVKurve[];
  series: Series[];
  frameProfiles: FrameProfileRoof[];
  sashProfiles: SashProfileRoof[];
  editedFrameProfile: Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>;
  consoles: Console[];
  consoleSets: ConsoleSet[];
  consoleSetAssignments: ConsoleSetAssignment[];
  editedSashProfile: Edit<SashProfileRoof>;
  testPhases: TestPhaseRoof[];
  editedTestPhase: TestPhaseRoofWithOptionalId | undefined;
  editedTestPhaseSystem: TestPhaseSystemRoofWithOptionalId | undefined;
  editedTestPhaseSeries: TestPhaseSeriesRoofWithOptionalId | undefined;
  editedTestPhaseAerodynamicCase:
    | TestPhaseAerodynamicCaseRoofWithOptionalId
    | undefined;
  editedTestPhaseApplication:
    | TestPhaseApplicationRoofWithOptionalId
    | undefined;
  editedTestPhaseApplicationOverview?:
    | TestPhaseApplicationOverviewWithOptionalId
    | {};
  editedTestPhaseValid: boolean;
  editedWindDeflector: WindDeflectorData | undefined;
  seriesENMaxStrokesRoof: SeriesENMaxStrokeRoof[];
  editedSeriesENMaxStrokesRoof: SeriesENMaxStrokeRoof[] | undefined;
}

export interface TestPhaseSystemRoof extends TestPhaseSystemRoofWithOptionalId {
  id: number;
}

export interface TestPhaseSystemRoofWithOptionalId {
  id?: number;
  system: System;
  klassFunk: Funktionssicherheit;
  umgebungstemp: number;
  klassWaermebest: string;
  maxWindLoad: number | undefined;
  maxSnowLoad: number | undefined;
  testPhaseSeries: TestPhaseSeriesRoofWithOptionalId[];
}

export interface TestPhaseApplicationRoofWithOptionalId {
  id?: number;
  application: string;
  maxSashWeight: number | undefined | null;
  sashFrameDistanceMin: number | undefined | null;
  sashFrameDistanceMax: number | undefined | null;
  testPhaseAerodynamicCaseRoofs: TestPhaseAerodynamicCaseRoofWithOptionalId[];
  readonly doubleFlapSideHung: boolean;
  readonly doubleFlapBottomOrTopHung: boolean;
}

export interface TestPhaseWinddeflectorConfiguration {
  largerOrEqualThanAngleOfInstallation: number;
  smallerOrEqualThanAngleOfInstallation: number;
  numberOfWindDeflectors: number;
}

export interface TestPhaseAerodynamicCaseRoofWithOptionalId {
  maximumRatioWidthToHeight: number | undefined;
  smallerOrEqualSashHeight: number | undefined;
  largerOrEqualSashHeight: number | undefined;
  windDeflectorConfiguration: TestPhaseWinddeflectorConfiguration[];
  id?: number;
  aerodynamicCase: AERODYNAMIC_CASE;
  maxFluegelflaeche: number | undefined;
  cvCurve?: CVKurve | undefined;
}

export interface TestPhaseSeriesRoof extends TestPhaseSeriesRoofWithOptionalId {
  id: number;
}

export interface TestPhaseSeriesRoofWithOptionalId {
  id?: number;
  series: Series;
  material: string;
  minGepruefteFluegelbreite: number | null;
  maxGepruefteFluegelbreite: number | null;
  inCertificate: boolean;
  applicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
  windLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[];
  snowLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[];
  anwendungen: TestPhaseApplicationRoofWithOptionalId[];
  frameProfiles: FrameProfileRoof[];
  sashProfiles: SashProfileRoof[];
}

export interface TestPhaseRoofWithOptionalId {
  // faDistanceToRidge: number | null;
  id?: number;
  name: string;
  active: ACTIVE_STATE;
  minAbstandDrehband: number | null;
  maxWLWHoeheAlu: number | null;
  maxWLWHoeheGlas: number | null;
  minEinbauneigungBereich3EK: number | null;
  maxEinbauneigungBereich3EK: number | null;
  fluegelhoeheKleinerGleichBereich3EK: number | null;
  maxOeffnungswinkel1Bereich3EK: number | null;
  fluegelhoeheGroesserBereich3EK: number | null;
  maxOeffnungswinkel2Bereich3EK: number | null;
  minEinbauneigungBereich1DK: number | null;
  maxEinbauneigungBereich1DK: number | null;
  verhaeltnisFluegelbreiteFluegelhoeheBereich1DK: number | null;
  testPhaseSystems: TestPhaseSystemRoofWithOptionalId[];
}

export interface TestPhaseRoof extends TestPhaseRoofWithOptionalId {
  id: number;
}

export interface SashProfileRoof extends NewSashProfileRoof {
  id: number;
}

export interface NewSashProfileRoof extends BaseProfileData {
  verriegelungsbeschlagGeeignet: boolean;
  maxEinspannstaerke: number;
  minEinspannstaerke: number;
  minFuellungsdicke: number;
  maxFuellungsdicke: number;
  maxZulaessigesFluegelgewicht: number;
  maxFluegelbreite: number;
  maxFluegelhoehe: number;
  minEinbauneigung: number;
  maxEinbauneigung: number;
  abstandAchseFluegelBei40: number;
  abstandAchseFluegelBei50: number;
  abstandAchseFluegelBei60: number;
  abstandAchseFluegelBei70: number;
  abstandAchseFluegelBei80: number;
  isolation: boolean;
  series: Series;
}

export interface FrameProfileRoof extends NewFrameProfileRoof {
  id: number;
}

export interface NewFrameProfileRoof extends BaseProfileData {
  einspannstaerke: number;
  einspanntiefe: number;
  ansichtsbreite_innen_gerade: number;
  ansichtsbreite_innen_schraege: number;
  profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt: boolean;
  profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt: boolean;
  profiltypLochfenster: boolean;
  isolation: boolean;
  series: Series;
}

export interface DoppelklappeDrehDreh {
  openingAngle: number;
  avLarger6: number;
  avSmallerEqual6: number;
  avSmallerEqual3: number;
  avSmallerEqual1Dot5: number;
}

export interface WindDeflectorData {
  id: number;
  bezeichnung: string;
  maxWLWLaengeBlech: number | undefined;
  maxWLWLaengeGlas: number | undefined;
  platzbedarfKonsoleBlech: number | undefined;
  platzbedarfKonsoleGlas: number | undefined;
  wlwHoehe1: number | undefined;
  wlwHoehe2: number | undefined;
  wlwHoehe3: number | undefined;
  bezeichnungGlashalterEK: string;
  abstandMontagesaetzeEK: number | undefined;
  bezeichnungGlashalterDK: string;
  abstandMontagesaetzeDK: number;
  windleitwandhalterGlasZweiWlwEK: WindDeflectorHolder[];
  windleitwandhalterGlasDreiWlwEK: WindDeflectorHolder[];
  windleitwandhalterBlechEK: WindDeflectorHolder;
  windleitwandhalterBlechDK: WindDeflectorHolder;
  windleitwandhalterGlasPultdachDK: WindDeflectorHolder[];
  windleitwandhalterGlasSatteldachDK: WindDeflectorHolder[];
  doppelklappen: DoubleFlap[];
  doppelklappenDrehDreh: DoppelklappeDrehDreh[];
  einzelklappenKipp: SingleFlap[];
  einzelklappenKlapp: SingleFlap[];
  sashWidth1: number | undefined;
  sashWidth2: number | undefined;
  minEinbauneigungWlwNachLichteBreite: number | undefined;
  maxEinbauneigungWlwNachLichteBreite: number | undefined;
}

export interface WindDeflectorHolder {
  id: number;
  eckhalter: string;
  mittelhalter: string;
  maxHoehe: number;
  abstandBeinhalteterGlashalter: number | undefined;
  active: boolean;
}

export interface RoofFlap {
  id: number | undefined;
  oeffnungswinkelMin: number | undefined;
  oeffnungswinkelMax: number | undefined;
  einbaulageMin: number | undefined;
  einbaulageMax: number | undefined;
  punkt1Hoehe: number | undefined;
  punkt1Breite: number | undefined;
  punkt2Hoehe: number | undefined;
  punkt2Breite: number | undefined;
  wlwHoehe: number | undefined;
  beschraenkt: boolean | undefined;
}

interface DoubleFlap extends RoofFlap {
  dachform: string | undefined;
}

type SingleFlap = RoofFlap;

export const INITIAL_STATE: AdminRoofState = {
  cvKurven: [],
  series: [],
  frameProfiles: [],
  sashProfiles: [],
  editedFrameProfile: {
    id: null,
    einspannstaerke: null,
    einspanntiefe: null,
    ansichtsbreite_innen_gerade: null,
    ansichtsbreite_innen_schraege: null,
    profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt: null,
    profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt: null,
    profiltypLochfenster: null,
    isolation: null,
    series: null,
    seriesId: null,
    artNr: null,
    beschreibung: null,
    kammermassAussen: null,
    kammermassInnen: null,
    active: null,
    preview: null,
    bautiefe: null,
    maxFluegelgewicht: null,
    oeffnungsrichtungEinwaerts: null,
    oeffnungsrichtungAuswaerts: null,
    anwendungKipp: null,
    anwendungKlapp: null,
    anwendungDreh: null,
    anwendungSenkklapp: null,
    blockfenster: null,
    standardfenster: null,
    flaechenbuendig: null,
  },
  consoles: [],
  consoleSets: [],
  consoleSetAssignments: [],
  testPhases: [],
  editedSashProfile: {
    id: null,
    verriegelungsbeschlagGeeignet: null,
    maxEinspannstaerke: null,
    minEinspannstaerke: null,
    minFuellungsdicke: null,
    maxFuellungsdicke: null,
    maxZulaessigesFluegelgewicht: null,
    maxFluegelbreite: null,
    maxFluegelhoehe: null,
    minEinbauneigung: null,
    maxEinbauneigung: null,
    abstandAchseFluegelBei40: null,
    abstandAchseFluegelBei50: null,
    abstandAchseFluegelBei60: null,
    abstandAchseFluegelBei70: null,
    abstandAchseFluegelBei80: null,
    isolation: null,
    series: null,
    seriesId: null,
    artNr: null,
    beschreibung: null,
    kammermassAussen: null,
    kammermassInnen: null,
    active: null,
    preview: null,
    bautiefe: null,
    maxFluegelgewicht: null,
    oeffnungsrichtungEinwaerts: null,
    oeffnungsrichtungAuswaerts: null,
    anwendungKipp: null,
    anwendungKlapp: null,
    anwendungDreh: null,
    anwendungSenkklapp: null,
    blockfenster: null,
    standardfenster: null,
    flaechenbuendig: null,
  },
  editedTestPhase: undefined,
  editedTestPhaseSystem: undefined,
  editedTestPhaseSeries: undefined,
  editedTestPhaseAerodynamicCase: undefined,
  editedTestPhaseApplication: undefined,
  editedTestPhaseApplicationOverview: {},
  editedTestPhaseValid: false,
  windDeflectors: [],
  editedWindDeflector: undefined,
  seriesENMaxStrokesRoof: [],
  editedSeriesENMaxStrokesRoof: undefined,
};

function validateEditedTestPhase(state: AdminRoofState): AdminRoofState {
  if (!state.editedTestPhase) {
    return state;
  }

  const valididationResult = compact(
    flatten<unknown>([
      fieldsFilledRecord(
        state.editedTestPhase,
        [
          'name',
          'maxWLWHoeheAlu',
          'maxWLWHoeheGlas',
          'minEinbauneigungBereich3EK',
          'maxEinbauneigungBereich3EK',
          'fluegelhoeheKleinerGleichBereich3EK',
          'maxOeffnungswinkel1Bereich3EK',
          'fluegelhoeheGroesserBereich3EK',
          'minEinbauneigungBereich1DK',
          'maxEinbauneigungBereich1DK',
          'verhaeltnisFluegelbreiteFluegelhoeheBereich1DK',
        ],
        'TestPhase',
      ),
      state.editedTestPhase.testPhaseSystems.flatMap((s, i) =>
        fieldsFilledRecord(
          s,
          ['maxWindLoad', 'maxSnowLoad'],
          'testPhaseSystems',
          i,
        ),
      ),
      state.editedTestPhase.testPhaseSystems.flatMap(s =>
        s.testPhaseSeries.flatMap((se, i) =>
          fieldsFilledRecord(
            se,
            ['minGepruefteFluegelbreite', 'maxGepruefteFluegelbreite'],
            'testPhaseSeries',
            i,
          ),
        ),
      ),
      state.editedTestPhase.testPhaseSystems.flatMap(s =>
        s.testPhaseSeries.flatMap(se =>
          se.anwendungen.flatMap((a, i) =>
            fieldsFilledRecord(
              a,
              ['maxSashWeight', 'sashFrameDistanceMax', 'sashFrameDistanceMin'],
              'anwendungen',
              i,
            ),
          ),
        ),
      ),
      state.editedTestPhase.testPhaseSystems.flatMap(s =>
        s.testPhaseSeries.flatMap(se =>
          se.anwendungen.flatMap(a =>
            a.testPhaseAerodynamicCaseRoofs.flatMap((ac, i) =>
              fieldsFilledRecord(
                ac,
                ['maxFluegelflaeche', 'cvCurve'],
                'testPhaseAerodynamicCaseRoofs',
                i,
              ),
            ),
          ),
        ),
      ),
    ]),
  );
  const valid = valididationResult.length === 0;

  return { ...state, editedTestPhaseValid: valid };
}

export const adminRoofReducer: Reducer<
  AdminRoofState | undefined,
  ADMIN_ROOF_ACTIONS | TEST_PHASE_ROOF_ACTIONS
> = (
  state: AdminRoofState | undefined,
  action: ADMIN_ROOF_ACTIONS | TEST_PHASE_ROOF_ACTIONS,
) => {
  if (state === undefined) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_EDITED_STROKES_ROOF': {
      return { ...state, editedSeriesENMaxStrokesRoof: action.roofStrokes };
    }

    case 'UPDATE_SERIES_EN_MAX_STROKES_ROOF': {
      return {
        ...state,
        seriesENMaxStrokesRoof: action.seriesENMaxStrokesRoof,
      };
    }

    case 'UPDATE_WIND_DEFLECTORS': {
      return { ...state, windDeflectors: action.windDeflectors };
    }

    case 'ADD_SERIES_ROOF': {
      return { ...state, series: [action.series, ...state.series] };
    }

    case 'UPDATE_ALL_SERIES_ROOF': {
      return { ...state, series: action.series };
    }

    case 'UPDATE_SERIES_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.series, {
        id: action.series.id,
      });
      newState.series.splice(indexToUpdate, 1, action.series);
      return newState;
    }

    case 'ADD_CV_CURVE_ROOF': {
      return {
        ...state,
        cvKurven: [action.cvCurve, ...state.cvKurven],
      };
    }

    case 'UPDATE_CV_CURVE_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.cvKurven, {
        id: action.cvCurve.id,
      });
      newState.cvKurven.splice(indexToUpdate, 1, action.cvCurve);
      return newState;
    }

    case 'UPDATE_CV_KURVEN_ROOF': {
      return { ...state, cvKurven: action.cvKurven };
    }

    case 'UPDATE_FRAME_PROFILES_ROOF': {
      return { ...state, frameProfiles: action.frameProfiles };
    }

    case 'UPDATE_FRAME_PROFILE_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.frameProfiles, {
        id: action.frameProfile.id,
      });
      newState.frameProfiles.splice(indexToUpdate, 1, action.frameProfile);
      return newState;
    }

    case 'UPDATE_SASH_PROFILES_ROOF': {
      return { ...state, sashProfiles: action.sashProfiles };
    }

    case 'UPDATE_SASH_PROFILE_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.sashProfiles, {
        id: action.sashProfile.id,
      });
      newState.sashProfiles.splice(indexToUpdate, 1, action.sashProfile);
      return newState;
    }

    case 'ADD_FRAME_PROFILE_ROOF': {
      return {
        ...state,
        frameProfiles: [action.frameProfile, ...state.frameProfiles],
      };
    }

    case 'ADD_SASH_PROFILE_ROOF': {
      return {
        ...state,
        sashProfiles: [action.SashProfile, ...state.sashProfiles],
      };
    }

    case 'UPDATE_EDITED_FRAME_PROFILE_ROOF': {
      return { ...state, editedFrameProfile: action.frameProfile };
    }

    case 'UPDATE_CONSOLES_ROOF': {
      return { ...state, consoles: action.consoles };
    }

    case 'UPDATE_CONSOLE_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.consoles, {
        id: action.console.id,
      });
      newState.consoles.splice(indexToUpdate, 1, action.console);
      return newState;
    }

    case 'ADD_CONSOLE_ROOF': {
      return { ...state, consoles: [action.console, ...state.consoles] };
    }

    case 'UPDATE_CONSOLE_SETS_ROOF': {
      return { ...state, consoleSets: action.consoleSets };
    }

    case 'UPDATE_CONSOLE_SET_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.consoleSets, {
        id: action.consoleSet.id,
      });
      newState.consoleSets.splice(indexToUpdate, 1, action.consoleSet);
      return newState;
    }

    case 'ADD_CONSOLE_SET_ROOF': {
      return {
        ...state,
        consoleSets: [action.consoleSet, ...state.consoleSets],
      };
    }

    case 'UPDATE_CONSOLE_SET_ASSIGNMENTS_ROOF': {
      return {
        ...state,
        consoleSetAssignments: action.consoleSetAssignments,
      };
    }

    case 'UPDATE_CONSOLE_SET_ASSIGNMENT_ROOF': {
      const newState = _.cloneDeep(state);
      const indexToUpdate = _.findIndex(newState.consoleSetAssignments, {
        id: action.consoleSetAssignment.id,
      });
      newState.consoleSetAssignments.splice(
        indexToUpdate,
        1,
        action.consoleSetAssignment,
      );
      return newState;
    }

    case 'ADD_CONSOLE_SET_ASSIGNMENT_ROOF': {
      return {
        ...state,
        consoleSetAssignments: [
          action.consoleSetAssignment,
          ...state.consoleSetAssignments,
        ],
      };
    }

    case 'UPDATE_EDITED_SASH_PROFILE_ROOF': {
      return { ...state, editedSashProfile: action.sashProfile };
    }

    case 'UPDATE_TEST_PHASES_ROOF': {
      return {
        ...state,
        testPhases: action.testPhases,
      };
    }

    case 'UPDATE_TEST_PHASE_ROOF': {
      return {
        ...state,
        testPhases: replaceElement(state.testPhases, action.testPhase),
      };
    }

    case 'ADD_TEST_PHASE_ROOF': {
      return {
        ...state,
        testPhases: [action.testPhase, ...state.testPhases],
      };
    }

    //editable test phase actions
    case 'UPDATE_EDITED_TEST_PHASE_ROOF': {
      return validateEditedTestPhase({
        ...state,
        editedTestPhase: action.editedTestPhase,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_SYSTEM_ROOF': {
      if (!action.editedTestPhaseSystem) {
        return validateEditedTestPhase({
          ...state,
          editedTestPhaseSystem: undefined,
        });
      }

      const updatedSystem = {
        ...action.editedTestPhaseSystem,
        ...action.values,
      };

      if (state.editedTestPhase) {
        replaceInList(
          state.editedTestPhase.testPhaseSystems,
          action.editedTestPhaseSystem,
          updatedSystem,
        );
      }

      return {
        ...state,
        editedTestPhaseSystem: updatedSystem,
      };
    }

    case 'UPDATE_EDITED_TEST_PHASE_SERIES_ROOF': {
      if (!action.editedTestPhaseSeries) {
        return {
          ...state,
          editedTestPhaseSeries: undefined,
        };
      }

      const updatedSeries = {
        ...action.editedTestPhaseSeries,
        ...action.values,
      };

      if (state.editedTestPhaseSystem) {
        replaceInList(
          state.editedTestPhaseSystem.testPhaseSeries,
          action.editedTestPhaseSeries,
          updatedSeries,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseSeries: updatedSeries,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_APPLICATION_ROOF': {
      if (!action.editedTestPhaseApplication) {
        return {
          ...state,
          editedTestPhaseApplication: undefined,
        };
      }

      const updatedApplications = getUpdatedElementIfValueChanges(
        state.editedTestPhaseApplication,
        action.editedTestPhaseApplication,
        action.values,
      );

      if (
        state.editedTestPhaseApplication &&
        state.editedTestPhaseSeries?.anwendungen
      ) {
        replaceInList(
          state.editedTestPhaseSeries?.anwendungen,
          action.editedTestPhaseApplication,
          updatedApplications,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseApplication: updatedApplications,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_AERODYNAMIC_TYPE_ROOF': {
      if (!action.editedTestAerodynamicCase) {
        return {
          ...state,
          editedTestPhaseAerodynamicCase: undefined,
        };
      }

      const updatedAerodynamicCases = getUpdatedElementIfValueChanges(
        state.editedTestPhaseAerodynamicCase,
        action.editedTestAerodynamicCase,
        action.values,
      );

      if (
        state.editedTestPhaseAerodynamicCase &&
        state.editedTestPhaseApplication?.testPhaseAerodynamicCaseRoofs
      ) {
        replaceInList(
          state.editedTestPhaseApplication.testPhaseAerodynamicCaseRoofs,
          action.editedTestAerodynamicCase,
          updatedAerodynamicCases,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseAerodynamicCase: updatedAerodynamicCases,
      });
    }

    case 'UPDATE_EDITED_WIND_DEFLECTOR': {
      return {
        ...state,
        editedWindDeflector: action.windDeflector as WindDeflectorData,
      };
    }

    default:
      return state;
  }
};
