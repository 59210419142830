import { Action, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AdminState } from './adminStore';
import _ from 'lodash';
import {
  Funktionssicherheit,
  POSSIBLE_TEST_PHASE_AERODYNAMIC_CASES_OPTIONS,
  POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS_ROOF,
  UmgebungsTemp,
  Waermebestaendigkeit,
} from '../../admin/constants';
import { getTestPhaseFunctions } from '../../admin/facade/FacadeTestPhases';
import {
  ACTIVE_STATE,
  FrameProfileRoof,
  SashProfileRoof,
  TestPhaseAerodynamicCaseRoofWithOptionalId,
  TestPhaseApplicationRoofWithOptionalId,
  TestPhaseRoof,
  TestPhaseRoofWithOptionalId,
  TestPhaseSeriesRoofWithOptionalId,
  TestPhaseSystemRoof,
  TestPhaseSystemRoofWithOptionalId,
} from './adminRoofReducer';
import {
  Series,
  System,
  TestPhaseApplicationOverviewWithOptionalId,
  TestPhaseWindLoadOrSnowLoadWithOptionalId,
} from './adminFacadeReducer';
import { updateEditedTestPhaseProfileType } from './editedTestPhaseActions';

export interface UpdateEditableTestPhaseRoof
  extends Action<'UPDATE_EDITED_TEST_PHASE_ROOF'> {
  editedTestPhase: TestPhaseRoofWithOptionalId;
}

export interface UpdateEditedTestPhaseSystemRoof
  extends Action<'UPDATE_EDITED_TEST_PHASE_SYSTEM_ROOF'> {
  editedTestPhaseSystem: TestPhaseSystemRoofWithOptionalId | undefined;
  values?: Record<string, number | string>;
}

export interface UpdateEditedTestPhaseSeriesRoof
  extends Action<'UPDATE_EDITED_TEST_PHASE_SERIES_ROOF'> {
  editedTestPhaseSeries: TestPhaseSeriesRoofWithOptionalId | undefined;
  values?: Partial<TestPhaseSeriesRoofWithOptionalId>;
}

export interface UpdateEditedTestPhaseAerodynamicCase
  extends Action<'UPDATE_EDITED_TEST_PHASE_AERODYNAMIC_TYPE_ROOF'> {
  editedTestAerodynamicCase:
    | TestPhaseAerodynamicCaseRoofWithOptionalId
    | undefined;
  values?: Partial<TestPhaseAerodynamicCaseRoofWithOptionalId>;
}

export interface UpdateEditedTestPhaseApplicationRoof
  extends Action<'UPDATE_EDITED_TEST_PHASE_APPLICATION_ROOF'> {
  editedTestPhaseApplication:
    | TestPhaseApplicationRoofWithOptionalId
    | undefined;
  values?: Partial<TestPhaseApplicationRoofWithOptionalId>;
}

export type TEST_PHASE_ROOF_ACTIONS =
  | UpdateEditableTestPhaseRoof
  | UpdateEditedTestPhaseSystemRoof
  | UpdateEditedTestPhaseSeriesRoof
  | UpdateEditedTestPhaseApplicationRoof
  | UpdateEditedTestPhaseAerodynamicCase;

export function updateEditedTestPhaseRoof(
  editableTestPhase: TestPhaseRoofWithOptionalId,
): UpdateEditableTestPhaseRoof {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_ROOF',
    editedTestPhase: editableTestPhase,
  };
}

export function updateEditedTestPhaseSystemRoof(
  editedTestPhaseSystem: TestPhaseSystemRoofWithOptionalId | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseSystemRoof {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_SYSTEM_ROOF',
    editedTestPhaseSystem: editedTestPhaseSystem,
    values: values,
  };
}

export function updateEditedTestPhaseAerodynamicCaseRoof(
  editedAerodynamicCase: TestPhaseAerodynamicCaseRoofWithOptionalId | undefined,
  values?: Partial<TestPhaseAerodynamicCaseRoofWithOptionalId>,
): UpdateEditedTestPhaseAerodynamicCase {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_AERODYNAMIC_TYPE_ROOF',
    editedTestAerodynamicCase: editedAerodynamicCase,
    values: values,
  };
}

export function updateEditedTestPhaseSeriesRoof(
  editedTestPhaseSeries: TestPhaseSeriesRoofWithOptionalId | undefined,
  values?: Partial<TestPhaseSeriesRoofWithOptionalId>,
): UpdateEditedTestPhaseSeriesRoof {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_SERIES_ROOF',
    editedTestPhaseSeries: editedTestPhaseSeries,
    values: values,
  };
}

export function updateEditedTestPhaseApplicationRoof(
  editedTestPhaseApplication:
    | TestPhaseApplicationRoofWithOptionalId
    | undefined,
  values?: Partial<TestPhaseApplicationRoofWithOptionalId>,
): UpdateEditedTestPhaseApplicationRoof {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_APPLICATION_ROOF',
    editedTestPhaseApplication: editedTestPhaseApplication,
    values: values,
  };
}

export function initializeNewEditableTestPhaseRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const newEditableTestPhase: TestPhaseRoofWithOptionalId = {
      name: 'Neue Testphase',
      active: ACTIVE_STATE.INACTIVE,
      maxWLWHoeheAlu: null,
      maxWLWHoeheGlas: null,
      minAbstandDrehband: 66,
      minEinbauneigungBereich3EK: null,
      maxEinbauneigungBereich3EK: null,
      fluegelhoeheKleinerGleichBereich3EK: null,
      maxOeffnungswinkel1Bereich3EK: null,
      fluegelhoeheGroesserBereich3EK: null,
      maxOeffnungswinkel2Bereich3EK: null,
      minEinbauneigungBereich1DK: null,
      maxEinbauneigungBereich1DK: null,
      verhaeltnisFluegelbreiteFluegelhoeheBereich1DK: null,
      testPhaseSystems: [],
    };

    dispatch(updateEditedTestPhaseRoof(newEditableTestPhase));
  };
}

export function addTestPhaseSystemToEditableTestPhaseRoof(
  addedSystem: System,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedTestPhase: TestPhaseRoof = _.cloneDeep(
      getState().adminRoof.editedTestPhase as TestPhaseRoof,
    )!;

    const newTps: TestPhaseSystemRoofWithOptionalId = {
      system: addedSystem,
      klassWaermebest: Waermebestaendigkeit.B300E,
      klassFunk: Funktionssicherheit.RE1000PLUS,
      umgebungstemp: UmgebungsTemp.ZERO,
      maxWindLoad: undefined,
      maxSnowLoad: undefined,
      testPhaseSeries: [],
    };

    if (
      updatedTestPhase.testPhaseSystems &&
      !updatedTestPhase.testPhaseSystems
        .map(tps => tps.system.id)
        .includes(addedSystem.id)
    ) {
      updatedTestPhase.testPhaseSystems.push(newTps as TestPhaseSystemRoof);
    }
    dispatch(updateEditedTestPhaseRoof(updatedTestPhase));
  };
}

export const [
  aerodynamicCaseAlreadyIncludedInApplication,
  deselectedAerodynamicCases,
] = getTestPhaseFunctions(
  (aerodynamicCase: TestPhaseAerodynamicCaseRoofWithOptionalId) =>
    aerodynamicCase.aerodynamicCase,
  POSSIBLE_TEST_PHASE_AERODYNAMIC_CASES_OPTIONS,
);

export function addTestPhaseAerodynamicCaseToEditedApplication(
  addedAerodynamicCase: TestPhaseAerodynamicCaseRoofWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      aerodynamicCaseAlreadyIncludedInApplication(
        getState,
        state =>
          state.adminRoof.editedTestPhaseApplication
            ?.testPhaseAerodynamicCaseRoofs as TestPhaseAerodynamicCaseRoofWithOptionalId[],
        addedAerodynamicCase,
      )
    ) {
      return;
    }

    const updatedTestPhaseAerodynamicCaseList: TestPhaseAerodynamicCaseRoofWithOptionalId[] =
      [
        ...getState().adminRoof.editedTestPhaseApplication!
          .testPhaseAerodynamicCaseRoofs,
        addedAerodynamicCase,
      ];

    dispatch(
      updateEditedTestPhaseApplicationRoof(
        getState().adminRoof.editedTestPhaseApplication,
        { testPhaseAerodynamicCaseRoofs: updatedTestPhaseAerodynamicCaseList },
      ),
    );

    dispatch(updateEditedTestPhaseProfileType(undefined));
  };
}

export function removeTestPhaseAerodynamicCaseToEditedApplication(
  removedApplication: TestPhaseAerodynamicCaseRoofWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedList = [
      ...getState().adminRoof.editedTestPhaseApplication!
        .testPhaseAerodynamicCaseRoofs,
    ];

    deleteInList(updatedList, removedApplication);

    dispatch(
      updateEditedTestPhaseApplicationRoof(
        getState().adminRoof.editedTestPhaseApplication,
        { testPhaseAerodynamicCaseRoofs: updatedList },
      ),
    );

    dispatch(updateEditedTestPhaseAerodynamicCaseRoof(undefined));
  };
}

export function addTestPhaseSeriesToEditableTestPhaseSystemRoof(
  addedSeries: Series,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      getState()
        .adminRoof.editedTestPhaseSystem!.testPhaseSeries.map(
          series => series.series,
        )
        .includes(addedSeries)
    ) {
      return;
    }

    const newTestPhaseSeries: TestPhaseSeriesRoofWithOptionalId = {
      anwendungen: [],
      material: '',
      maxGepruefteFluegelbreite: null,
      minGepruefteFluegelbreite: null,
      inCertificate: false,
      snowLoads: [],
      series: addedSeries,
      applicationOverviews: [],
      windLoads: [],
      frameProfiles: [],
      sashProfiles: [],
    };

    const updatedSeriesList = [
      ...getState().adminRoof.editedTestPhaseSystem!.testPhaseSeries,
      newTestPhaseSeries,
    ];

    dispatch(
      updateEditedTestPhaseSystemRoof(
        getState().adminRoof.editedTestPhaseSystem,
        {
          testPhaseSeries: updatedSeriesList,
        },
      ),
    );
  };
}

export function removeTestPhaseSystemFromEditableTestPhaseRoof(
  removedSystem: System,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedTestPhase: TestPhaseRoof = _.cloneDeep(
      getState().adminRoof.editedTestPhase as TestPhaseRoof,
    )!;
    if (updatedTestPhase.testPhaseSystems) {
      const indexToDelete = (
        updatedTestPhase.testPhaseSystems as TestPhaseSystemRoof[]
      )
        .map(tps => tps.system.id)
        .indexOf(removedSystem.id);

      if (indexToDelete !== -1) {
        updatedTestPhase.testPhaseSystems.splice(indexToDelete, 1);
        dispatch(updateEditedTestPhaseRoof(updatedTestPhase));
      }
    }
    dispatch(updateEditedTestPhaseSystemRoof(undefined));
  };
}

export function removeTestPhaseSeriesFromEditableTestPhaseSystemRoof(
  removedSeries: Series,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedSeriesList = [
      ...getState().adminRoof.editedTestPhaseSystem!.testPhaseSeries,
    ];
    const indexToDelete = _.findIndex(
      updatedSeriesList,
      e => e.series === removedSeries,
    );

    if (indexToDelete !== -1) {
      updatedSeriesList.splice(indexToDelete, 1);
    }

    dispatch(
      updateEditedTestPhaseSystemRoof(
        getState().adminRoof.editedTestPhaseSystem,
        {
          testPhaseSeries: updatedSeriesList,
        },
      ),
    );

    dispatch(updateEditedTestPhaseSeriesRoof(undefined));
  };
}

export const [
  applicationAlreadyIncludedInOpeningTypeRoof,
  deselectedApplicationsRoof,
] = getTestPhaseFunctions(
  (application: TestPhaseApplicationRoofWithOptionalId) =>
    application.application,
  POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS_ROOF,
);

function deleteInList<T>(updatedList: T[], removedElement: T): void {
  const indexToDelete = _.findIndex(updatedList, e => e === removedElement);

  if (indexToDelete !== -1) {
    updatedList.splice(indexToDelete, 1);
  }
}

export function addTestPhaseApplicationToEditedSeriesOpeningDirectionRoof(
  addedApplication: TestPhaseApplicationRoofWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      applicationAlreadyIncludedInOpeningTypeRoof(
        getState,
        state =>
          state.adminRoof.editedTestPhaseSeries
            ?.anwendungen as TestPhaseApplicationRoofWithOptionalId[],
        addedApplication,
      )
    ) {
      return;
    }

    const updatedTestPhaseApplicationList: TestPhaseApplicationRoofWithOptionalId[] =
      [
        ...getState().adminRoof.editedTestPhaseSeries!.anwendungen,
        addedApplication,
      ];

    dispatch(
      updateEditedTestPhaseSeriesRoof(
        getState().adminRoof.editedTestPhaseSeries,
        { anwendungen: updatedTestPhaseApplicationList },
      ),
    );
    dispatch(updateEditedTestPhaseApplicationRoof(undefined));
  };
}

export function removeTestPhaseApplicationFromEditedSeriesRoof(
  removedApplication: TestPhaseApplicationRoofWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedApplicationList = [
      ...getState().adminRoof.editedTestPhaseSeries!.anwendungen,
    ];

    deleteInList(updatedApplicationList, removedApplication);

    dispatch(
      updateEditedTestPhaseSeriesRoof(
        getState().adminRoof.editedTestPhaseSeries,
        { anwendungen: updatedApplicationList },
      ),
    );

    dispatch(updateEditedTestPhaseApplicationRoof(undefined));
  };
}
